.logo {
    height: 56px;
    margin: 20px; 
  }
  
  .logoMini {
    height: 50px;
    width: 49px;
    margin: 15px; 
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
    [data-theme="dark"] .site-layout .site-layout-background {
      background: #141414;
    }
  
    .footerRight{
      float: right!important;
      padding-top: 30px;
  }
  
  .titulo {    
    font: 12px "Source Sans Pro", "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 10px 25px 10px 15px; 
    color: #fff; 
    text-align: center;       
  }
  
  .botonDetalle {
    background-color: #08979c;
    border-Color: #08979c;
  }
  
  .botonDetalle:hover{
    background-color: #13c2c2;
    border-color: #13c2c2;
  }
  
  .botonModificar{
    background-color: #d48806;
    border-color: #d48806;
  }
  
  .botonModificar:hover{
    background-color: #faad14;
    border-color: #faad14;
  }
  
  .login-form {
    max-width: 300px;
  }
  
  .login-form-button {
    width: 100%;
  }
  
  .centrarLogin{
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: -50px;
    width: 300px;
  }
  
  .botonCerrarSesion{
    float: right;
    margin-top: 20px;
    margin-right: 15px;
  }
  
  .usuarioSesion{
    margin-left: 20px;
  }